<template>
  <img :src="`/host-${theme}-300px.png`" :style="`height: ${height}px;`"/>
</template>
<script>
export default {
  props: {
    theme: String,
    height: [ Number, String ],
  },
}
</script>

<style lang="scss">
</style>
